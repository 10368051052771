import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import cx from "classnames";

import { useCommonStyles } from "../../../../utils/loan-application.styles";
import { borderColor } from "../../../../scss/colors.scss";
import Button from "../../components/Button";
import { debtOptions } from "../../../../commons/loan-application-constants";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "4px",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    gridRowGap: "8px",
    marginTop: "16px",
  },
  detailRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  editButton: {
    padding: "5px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "2px 8px",
    },
  },
  actionButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "16px",
  },
  cardContainer: {
    padding: "24px",
    borderRadius: "3px",
    width: "100%",
    border: `2px solid ${borderColor}`,
    [theme.breakpoints.down("xs")]: {
      padding: "16px",
    },
  },
}));

const DebtSummaryCard = ({ debt, onEdit, debtType, index }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const formatCurrency = (amount) => {
    if (!amount && amount !== 0) return "";
    return new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: "AUD",
    }).format(amount);
  };
  return (
    <Box classes={{ root: classes.cardContainer }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gridColumnGap="48px"
      >
        <Typography
          classes={{
            root: cx(commonClasses.fontSize18, commonClasses.fontWeightBold),
          }}
        >
          {debtOptions.find((option) => option.value === debtType)?.label}{" "}
          {index + 1}
        </Typography>
        <Button
          endIcon={<Edit />}
          onClick={onEdit}
          classes={classes.editButton}
        >
          Edit
        </Button>
      </Box>
      <Box classes={{ root: classes.detailsContainer }}>
        <Box classes={{ root: classes.detailRow }}>
          <Typography>Current Owing</Typography>
          <Typography>{formatCurrency(debt.currentOwing)}</Typography>
        </Box>
        <Box classes={{ root: classes.detailRow }}>
          <Typography>Repayment</Typography>
          <Typography>
            {formatCurrency(debt.loanRepaymentAmount)} {debt.repaymentFrequency}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DebtSummaryCard;
